import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import { nhost } from "../../nhost";
import { useAuthenticationStatus } from '@nhost/react'
import history from "@history.js";

function Auth(props) {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  const [user, setUser] = useState(null)

    async function userBackend (){
      try{
        let userData = await nhost.auth.getUser()
        setUser(userData)
      }
      catch(err){
        console.log(err)
      }
    } 

  useEffect(()=>{
    let componentMounted = true;
    userBackend();
      user&&componentMounted&& props.setUserData(user);
      return () => {
        componentMounted = false;
    }
  },[user, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps


    if(isLoading){
      return(
        <div>
          <h1>
            Loading...
          </h1>
        </div>
      )
    }
    if (!isAuthenticated) {
      history.push('/session/signin');
    }


    const { children } = props;
    
    return <Fragment>{children}</Fragment>;
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(mapStateToProps, { setUserData })(Auth);
