import { gql } from '@apollo/client';

const INSERT_FUNCTION_USER = gql`
mutation insertFunctionUser($objects: [function_users_insert_input!]!) {
    insert_function_users(objects: $objects) {
      affected_rows
    }
  }
`

export default INSERT_FUNCTION_USER;


