import React from "react";

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
      style={id==="selectAll"? {width: "16px", height: "16px", marginTop: "2px", marginLeft: "3px"} : {width: "11px", height: "11px"}}           
    />
  );
};

export default Checkbox;