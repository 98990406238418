import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingButton from "@gull/components/buttons/LoadingButton";
import UPDATE_ORG from "app/services/graphql/UpdateOrg";

export function UpdateOrgModal(props) {
    const [formState, setFormState] = useState({
        name: "",
        industry: "",
        ebitda: "",
        revenue: "",
        hc: "",
        layer: "",
      });

  const { show, onHide, refetch, org_id, orgs } = props;

  const org = orgs&& orgs.filter(i=>i.org_id===org_id)[0]

  const [updateOrg, { loading, error }] = useMutation(UPDATE_ORG, {
    onCompleted(orgData) {
      onHide();
      refetch();
    }
  })

  if (error) {
    console.log(error);
  }

  useEffect(()=>{
      const selectedOrg = org&& org.organization
    org&&setFormState({
        name: selectedOrg.name,
        industry: selectedOrg.industry,
        ebitda: selectedOrg.ebitda,
        revenue: selectedOrg.revenue,
        hc: selectedOrg.hc,
        layer: selectedOrg.layer,
    })
},[org])

  const orgSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("email is required"),
    password: yup
      .string()
      .min(8, "Password must be 8 character long")
      .required("password is required"),
  });

  const handleSubmit = (values, { isSubmitting }) => {
    const { name, industry, ebitda, revenue, hc, layer } = values;
      updateOrg({
        variables: {
            org_id: org_id,
          _set: {
            name: name,
            industry: industry,
            ebitda: ebitda,
            revenue: revenue,
            hc: hc,
            layer: layer
          },
        },
      });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Organizasyonu Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-5">
              <div className="card-body">
                <Formik initialValues={formState} onSubmit={handleSubmit} enableReinitialize>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          İsim
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Organizasyon ismini girin"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Endüstri
                        </label>
                        <div className="col-sm-10">
                          <select
                            type="text"
                            className="form-control"
                            name="industry"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Hizmet verdiği sektörü seçin"
                            value={values.industry}
                          >
                            <option>Hizmet verdiği sektörü seçin</option>
                            <option value="Communication, Media and Technology">Communication, Media and Technology</option>
                            <option value="Consumer">Consumer</option>
                            <option value="Financial & Professional Services">Financial & Professional Services</option>
                            <option value="Industrial Products & Services">Industrial Products & Services</option>
                            <option value="Insurance">Insurance</option>
                            <option value="Pharmaceutical">Pharmaceutical</option>
                            <option value="Private Health Care">Private Health Care</option>
                            <option value="Public Sector">Public Sector</option>
                        </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Ebitda
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control"
                            name="ebitda"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Ebitda bilgisini girin"
                            value={values.ebitda}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Ciro
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control"
                            name="revenue"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Cİro bilgisini girin"
                            value={values.revenue}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Çalışan sayısı
                        </label>
                        <div className="col-sm-10">
                          <select
                            type="text"
                            className="form-control"
                            name="hc"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Çalışan sayısını seçin"
                            value={values.hc}
                          >
                              <option>Çalışan sayısını seçin</option>
                              <option value="1-1000">1-1000</option>
                              <option value="1001-5000">1001-5000</option>
                              <option value="5001-25000">5001-25000</option>
                              <option value="25001+">25001+</option>

                        </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Kademe
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control"
                            name="layer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Mevcut kademeyi girin"
                            value={values.layer}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-10 d-flex">
                          <LoadingButton
                            type="submit"
                            className="text-capitalize btn-primary"
                            variant="primary"
                            loading={loading}
                            animation="border"
                            spinnerSize="sm"
                            disabled={!dirty&&errors}
                          >
                            Güncelle
                          </LoadingButton>
                          <button
                            type="button"
                            className="btn btn-secondary ml-1"
                            onClick={onHide}
                          >
                            İptal
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateOrgModal;
