import { lazy } from "react";

const Signup = lazy(() => import("./Signup"));

const Signin = lazy(() => import("./Signin"));

const ForgotPassword = lazy(() => import("./ForgotPassword"));

const NewPassword = lazy(() => import("./NewPassword"));

const ChangePassword = lazy(() => import("./ChangePassword"));

const Error404 = lazy(() => import("./Error"));

const sessionsRoutes = [
  {
    path: "/session/signup",
    component: Signup
  },
  {
    path: "/session/signin",
    component: Signin
  },
  {
    path: "/session/new-password",
    component: NewPassword
  },
  {
    path: "/session/change-password",
    component: ChangePassword
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/session/404",
    component: Error404
  }
];

export default sessionsRoutes;
