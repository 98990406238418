import {
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
  } from "../actions/SignupActions";
  
  const initialState = {
    success: false,
    loading: false,
    error: "",
  };
  
  const SignupReducer = function(state = initialState, action) {
    switch (action.type) {
      case SIGNUP_SUCCESS: {
        return {
          ...state,
          success: true,
          loading: false
        };
      }
      case SIGNUP_ERROR: {
        return {
          success: false,
          loading: false,
          error: action.data
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default SignupReducer;
  