import { lazy } from "react";

const Org = lazy(() => import("./Org"));
const Roles = lazy(() => import("./roles/Roles"));
const JobDescriptions = lazy(() => import("./jobdescriptions/JobDescriptions"));
const Activities = lazy(() => import("./activities/Activities"))
const Parameters = lazy(() => import("./parameters/Parameters"))
const Users = lazy(() => import("./users/Users"))
const WorkDays = lazy(() => import("./workDays/WorkDays"))

const orgRoutes = [
  {
    path: "/organization/chart",
    component: Org
  },
  {
    path: "/organization/roles",
    component: Roles
  },
  {
    path: "/organization/parameters",
    component: Parameters
  },
  {
    path: "/organization/activities",
    component: Activities
  },
  {
    path: "/jobdescriptions",
    component: JobDescriptions
  },
  {
    path: "/organization/users",
    component: Users
  },
  {
    path: "/organization/efficiency",
    component: WorkDays
  }
  
];

export default orgRoutes;
