import { setUserData } from "./UserActions";
import history from "@history.js";
import axios from "axios"

export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export function firebaseSignupEmailPassword({ email, password, username, surname }) {
  return dispatch => {
 
  };
}
