import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Breadcrumb, Loading } from "@gull";
import { connect } from "react-redux";
import "../kpis/Kpis.css";
import GET_USER_ORGS from "app/services/graphql/GetUserOrgs";
import { getTotals } from "./helpers";
import NewOrgModal from "./newOrgModal";
import DELETE_ORG from "app/services/graphql/DeleteOrg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import UpdateOrgModal from "./updateOrgModal";

function MyOrganizations(props) {
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [org_id, setOrg_id] = useState(false);
console.log(props.user)
  const [getUserOrgs, { loading, error, data, refetch }] = useLazyQuery(GET_USER_ORGS, {
    variables: { user_id: props.user.id },
  });

  const [deleteOrg, { loading: mutLoading, error: mutError }] =
    useMutation(DELETE_ORG, {
      onCompleted(id) {
        refetch();
      },
    });

    useEffect(()=>{
      let componentMounted = true;
      if(componentMounted&&props.user.id){ getUserOrgs()}
      return () => {
        componentMounted = false;
    }
    },[props.user.id]) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) console.log(error);

  const orgs = data && data.users[0].user_orgs;
  const { orgData, efficiency, user } = props;

  const rate =efficiency&& Number(efficiency) === 80? 1.250688 : Number(efficiency) === 85? 1.176928 : Number(efficiency) === 90? 1.1111111 : 1

  let workDayData = orgData&&orgData[0]&& orgData[0].calculated_workday.filter(i=>i.year===new Date().getFullYear())[0]

  const calculatedWorkday =
    workDayData &&
    Number(workDayData.workdays * Number(workDayData.work_hours))*efficiency/100

  const handleClose = () => {setShow(false);setUpdateShow(false)}

  const handleSubmit = (values) => {
    deleteOrg({
      variables: {
        org_id: org_id,
      },
    });
  };

  const popoverDel = (props) => {
    return (
      <Popover id="popover-basic" {...props}>
        <Popover.Title>
          <span className="text-danger">
            Oragnizasyon ve altındaki tüm departmanlar, fonksiyonlar ve roller
            silinecek. Bu geri dönüşü olmayan bir işlemdir.
          </span>{" "}
        </Popover.Title>
        <Popover.Content>
          <div className="col-md-12">
            <div className="d-flex">
              <button
                onClick={handleSubmit}
                className="btn btn-danger"
                type="button"
              >
                {mutLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Yine de Sil
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  document.body.click();
                }}
                className="btn btn-secondary ml-2"
                type="button"
              >
                İptal
              </button>
            </div>
            {mutError && (
              <div className="text-danger">
                Bir hata oluştu, lütfen tekrar deneyiniz.
              </div>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div>
      <Breadcrumb
        routeSegments={[{ name: "Organizasyonlarım", path: "/myorgs" }]}
      ></Breadcrumb>
      <div className="main-kpi-card-wrapper">
        {loading ? (
          <Loading /> 
        ) : (
          orgs &&
          orgs.map((item) => {
            let i = item.organization;
            const totals = getTotals(i);
            let existing = i.roles_aggregate.aggregate.sum.existing;
            return (
              <div key={i.id} className="main-kpi-card">
                <div className="main-kpi-card-body d-flex">
                  <div>
                    <h3>{i.name}</h3>
                  </div>
                  <div className="ml-auto mt-auto mb-auto text-primary">
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={popoverDel}
                      rootClose
                    >
                      <span
                        onClick={() => setOrg_id(i.id)}
                        className="cursor-pointer"
                      >
                        <small>Sil</small>
                      </span>
                    </OverlayTrigger>
                    <span onClick={()=>{setUpdateShow(true); setOrg_id(i.id)}} className="ml-2 cursor-pointer">
                      <small>Güncelle</small>
                    </span>
                  </div>
                </div>
                <div className="top-roles-card-header mt-3">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <div className="col-sm-6 border-right text-center">
                      <span>Mevcut Fte</span>
                      <br></br>
                      <h3 style={{ color: "dimgray" }}>{existing || "-"}</h3>
                    </div>
                    <div className="col-sm-6 text-center">
                      <span>Hedef Fte</span>
                      <br></br>
                      <h3>{Math.ceil(totals * rate) || "-"} </h3>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 d-flex justify-content-center mt-3">
                  <div className="col-sm-6 border-right text-center">
                    <span>Mevcut Ebitda/HC</span>
                    <br></br>
                    <h3 style={{ color: "dimgray" }}>
                      {Math.ceil(i.ebitda / existing).toLocaleString("tr") ||
                        "-"}
                    </h3>
                  </div>
                  <div className="col-sm-6 text-center">
                    <span>Hedef Ebitda/HC</span>
                    <br></br>
                    <h3>
                      {Math.ceil(
                        i.ebitda / (totals * rate)
                      ).toLocaleString("tr") || "-"}
                    </h3>
                  </div>
                </div>
                <div className="col-sm-12 d-flex justify-content-center mt-3">
                  <div className="col-sm-6 border-right text-center">
                    <span>Mevcut Revenue/HC</span>
                    <br></br>
                    <h3 style={{ color: "dimgray" }}>
                      {Math.ceil(i.revenue / existing).toLocaleString("tr") ||
                        "-"}
                    </h3>
                  </div>
                  <div className="col-sm-6 text-center">
                    <span>Hedef Revenue/HC</span>
                    <br></br>
                    <h3>
                      {Math.ceil(
                        i.revenue / (totals * rate)
                      ).toLocaleString("tr") || "-"}
                    </h3>
                  </div>
                </div>

                <div className="d-flex mt-5">
                  <div className="col-sm-4">
                    <small>Sektör</small>
                  </div>
                  <div className="col-sm-8">
                    <strong>{i.industry}</strong>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-sm-4">
                    <small>Ölçek</small>
                  </div>
                  <div className="col-sm-8">
                    <strong>{i.hc}</strong>
                  </div>
                </div>
              </div>
            );
          })
        )}
        <div
          style={{
            fontSize: "1em",
            color: "rgba(37, 90, 155, 0.8)",
            cursor: "pointer",
          }}
          onClick={() => setShow(true)}
          className="mt-auto mb-auto ml-5 text-center"
        >
          <i className="i-Add font-weight-bold"></i>
          <p className="iconText">Organizasyon Ekle</p>
        </div>
      </div>
      <NewOrgModal
        user={user}
        show={show}
        onHide={handleClose}
        refetch={refetch}
        size="lg"
      />
      <UpdateOrgModal
        user={user}
        show={updateShow}
        org_id={org_id}
        onHide={handleClose}
        refetch={refetch}
        size="lg"
        orgs={orgs}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    orgData: state.org.orgData,
    efficiency: state.org.efficiency,
    user: state.user,
  };
};

export default connect(mapStateToProps)(MyOrganizations);
