import React from "react";
import { useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingButton from "@gull/components/buttons/LoadingButton";
import INSERT_ORGS from "app/services/graphql/InsertOrg";
import INSERT_USER_ORG from "app/services/graphql/InsertUserOrg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUserOrgs } from "../../../app/redux/actions/OrgActions";

export function NewOrgModal(props) {
  const { show, onHide, refetch, user, org_id } = props;

  const [insertUserOrg, {loading: uoLoading, error: uoError}] = useMutation(INSERT_USER_ORG, {
    onCompleted(orgData) {
      onHide();
      refetch();
      if(orgData&&!org_id){props.getUserOrgs(orgData.insert_user_orgs_one.org_id)}
    }
  })

  if(uoError)console.log(uoError)

  const [insertOrg, { error, loading }] = useMutation(INSERT_ORGS, {
    onCompleted(orgData) {
        const org_id = orgData&&orgData.insert_organizations_one.id
        insertUserOrg({
            variables: {
                object: {
                    user_id: user.id,
                    org_id: org_id
                }
            }
        })
    },
  });

  if (error) {
    console.log(error);
  }

  const orgSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("email is required"),
    password: yup
      .string()
      .min(8, "Password must be 8 character long")
      .required("password is required"),
  });

  const handleSubmit = (values, { isSubmitting }) => {
    const { name, industry, ebitda, revenue, hc, layer } = values;
      insertOrg({
        variables: {
          object: {
            name: name,
            industry: industry,
            ebitda: ebitda,
            revenue: revenue,
            hc: hc,
            layer: layer
          },
        },
      });
  };

  let initials = {
    name: "",
    industry: "",
    ebitda: "",
    revenue: "",
    hc: "",
    layer: "",
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Yeni Organizasyon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-5">
              <div className="card-body">
                <Formik initialValues={initials} onSubmit={handleSubmit}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          İsim
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Organizasyon ismini girin"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Endüstri
                        </label>
                        <div className="col-sm-10">
                          <select
                            type="text"
                            className="form-control"
                            name="industry"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Hizmet verdiği sektörü seçin"
                          >
                            <option>Hizmet verdiği sektörü seçin</option>
                            <option value="Communication, Media and Technology">Communication, Media and Technology</option>
                            <option value="Consumer">Consumer</option>
                            <option value="Financial & Professional Services">Financial & Professional Services</option>
                            <option value="Industrial Products & Services">Industrial Products & Services</option>
                            <option value="Insurance">Insurance</option>
                            <option value="Pharmaceutical">Pharmaceutical</option>
                            <option value="Private Health Care">Private Health Care</option>
                            <option value="Public Sector">Public Sector</option>
                        </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Ebitda
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control"
                            name="ebitda"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Ebitda bilgisini girin"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Ciro
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control"
                            name="revenue"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Cİro bilgisini girin"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Çalışan sayısı
                        </label>
                        <div className="col-sm-10">
                          <select
                            type="text"
                            className="form-control"
                            name="hc"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Çalışan sayısını seçin"
                          >
                              <option>Çalışan sayısını seçin</option>
                              <option value="1-1000">1-1000</option>
                              <option value="1001-5000">1001-5000</option>
                              <option value="5001-25000">5001-25000</option>
                              <option value="25001+">25001+</option>

                        </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Kademe
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control"
                            name="layer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Mevcut kademeyi girin"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-10 d-flex">
                          <LoadingButton
                            type="submit"
                            className="text-capitalize btn-primary"
                            variant="primary"
                            loading={loading || uoLoading}
                            animation="border"
                            spinnerSize="sm"
                          >
                            Ekle
                          </LoadingButton>
                          <button
                            type="button"
                            className="btn btn-secondary ml-1"
                            onClick={onHide}
                          >
                            İptal
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    getUserOrgs: PropTypes.func.isRequired,
    user: state.user,
    org_id: state.org.userOrgs
  };
};

export default connect(mapStateToProps, {getUserOrgs})(NewOrgModal);
