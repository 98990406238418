import { setUserData } from "./UserActions";
import history from "@history.js";
import { nhost } from "../../../nhost";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";


export function loginWithEmailAndPassword({ email, password }) {
  return async dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });


    await nhost.auth.signIn({
      email, password
    }).then(async data=>{
      const user = await nhost.auth.getUser();
      console.log(user)
      if(user){
  
          dispatch(setUserData(user));
  
          const role= "superAdmin"
    
          if(role==="superAdmin" || role ==="cLevel"){history.push({
            pathname: "/myorgs"
          });}
    
          if(role==="superUser" || role==="director"){history.push({
            pathname: "/dashboard"
          });}
    
          if(role==="manager" || role==="keyUser"){history.push({
            pathname: "/organization/activities"
          });}
    
          if(role==="semiGod"){history.push({
            pathname: "/editors"
          });}
          
          return dispatch({
            type: LOGIN_SUCCESS
          });
        }
    }).catch(error=>{
      return dispatch({
        type: LOGIN_ERROR,
        payload: error
      });
    })
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD
    });

  };
}