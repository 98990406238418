import { gql } from "@apollo/client";

const DELETE_USERS = gql`
  mutation deleteUsers($ids: [uuid!]!) {
    delete_users(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export default DELETE_USERS;
