import { lazy } from "react";
import SemiGod from "./SemiGod";
import { authRoles } from "app/auth/authRoles";

const Kpis = lazy(() => import("./SemiGod"));

const myOrgRoutes = [
  {
    path: "/editors",
    component: SemiGod,
    auth: authRoles.sg
  }
];

export default myOrgRoutes;
