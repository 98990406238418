import { lazy } from "react";

const Departments = lazy(() => import("./Departments"));

const departmentRoutes = [
  {
    path: "/departments/:id",
    component: Departments
  }
];

export default departmentRoutes;
