import { lazy } from "react";

const Kpis = lazy(() => import("./Kpis"));


const kpisRoutes = [
  {
    path: "/kpis",
    component: Kpis,
  }
];

export default kpisRoutes;
