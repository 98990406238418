import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { connect } from "react-redux";
import SEMIGOD from "app/services/graphql/SemiGod";
import { OverlayTrigger, Popover } from "react-bootstrap";
import LoadingButton from "@gull/components/buttons/LoadingButton";
import DELETE_USERS from "app/services/graphql/DeleteUsers";
import DELETE_INVITEDS from "app/services/graphql/deleteInviteds";
import DELETE_FUNCTION_USER from "app/services/graphql/DeleteFunctionUser";
import INSERT_FUNCTION_USER from "app/services/graphql/InsertFunctionUser";
import Select from "react-select";
import moment from "moment";
import "moment/locale/tr";
import { Formik } from "formik";
import CheckBox from "../organization/users/CheckBox";
import { nhost } from "../../../nhost";
import './semiGod.css'
import UpdateOrgModal from "./updateOrgModal";
import DELETE_ORG from "app/services/graphql/DeleteOrg";
import { setUserData } from "app/redux/actions/UserActions";
import { PropTypes } from "prop-types";
import UPDATE_DEFAULTROLE from "app/services/graphql/UpdateDefaultRole";

function SemiGod(props) {
  const [updateShow, setUpdateShow] = useState(false);
  const [org_id, setOrg_id] = useState(false);

  const [deleteOrg, { loading: mutLoading, error: mutError, data: mutData }] =
  useMutation(DELETE_ORG, {
    onCompleted(id) {
      refetch();
    },
  });

  const filterColumns = [
    { id: 0, property: "display_name", columnLabel: "Email" },
    { id: 1, property: "type", columnLabel: "Tipi" },
    { id: 2, property: "birim", columnLabel: "Birimler" },
  ];
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [formState, setFormState] = useState({
    type: "",
  });
  const [isCheck, setIsCheck] = useState([]);
  const [selectedUser, setUser] = useState(null);
  const [depToRemove, setDepToRemove] = useState(null);
  const [filterState, setFilterState] = useState({
    data: [],
    columns: filterColumns,
    filterObj: filterColumns.reduce(
      (r, { property }) => ((r[property] = ""), r),
      {}
    ),
  });
  const { error: godError, data: godData, refetch } = useQuery(SEMIGOD, {
    skip: !props.user
  });

  const { user } = props

  if (godError) console.log(godError);

  const users = godData && godData.users;

  const onFilterApply = ({ target: { name, value } }) => {
    const newFilterObj = { ...filterState.filterObj, [name]: value };
    setFilterState({
      ...filterState,
      filterObj: newFilterObj,
      data:
        godData &&
        godData.users.filter((props) =>
          Object.entries(newFilterObj).every(
            ([key, val]) =>
              !val.length ||
              props[key]
                .toLocaleLowerCase("tr")
                .includes(val.toLocaleLowerCase("tr"))
          )
        ),
    });
  };

  useEffect(() => {
    let componentMounted = true;
    // inititate users filter state with all the activities
    componentMounted&&godData&& godData.users&& setFilterState({ data: godData.users });
    return () => {
      componentMounted = false;
  }  
  }, [users]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => setUpdateShow(false)

  const [
    updateDefaultRole,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_DEFAULTROLE, {
    onCompleted(updateData) {
      refetch();
      setIsCheck([]);
      setIsCheckAll(false);
      setFormState({ type: "" });
      document.body.click();
    },
  });

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(filterState.data.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSelectOne = (e) => {
    // select activities one by one
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const [
    deleteUsers,
    { loading: delUserLoading, error: delUserError },
  ] = useMutation(DELETE_USERS, {
    onCompleted(deleteData) {
      refetch();
      setIsCheck([]);
      setIsCheckAll(false);
      document.body.click();
    },
  });

  const [
    deleteInviteds,
    { loading: delInvLoading, error: delInvError },
  ] = useMutation(DELETE_INVITEDS, {
    onCompleted(delInvData) {
      refetch();
      setIsCheck([]);
      setIsCheckAll(false);
      document.body.click();
    },
  });

  const [
    deleteFunctionUser,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_FUNCTION_USER, {
    onCompleted(deleteData) {
      refetch();
      setIsCheck([]);
      setIsCheckAll(false);
      document.body.click();
    },
  });

  const [insertFunctionUser, { loading, error }] = useMutation(
    INSERT_FUNCTION_USER,
    {
      onCompleted(inData) {
        refetch();
        document.body.click();
      },
    }
  );

  const handleDelUsersSubmit = (values) => {
    let userIds = [];
    let invitedIds = [];

    //check if isCheck has length and if not the user is deleting only 1 user which is in state user.
    if (isCheck.length) {
      isCheck.map((i) => {
        if (users.find((item) => item["id"] === i)) {
          userIds.push(i);
        } else {
          invitedIds.push(i);
        }
      });

      if (userIds && userIds.length) {
        deleteUsers({
          variables: { ids: userIds },
        });
      }
      if (invitedIds && invitedIds.length) {
        deleteInviteds({
          variables: {
            ids: invitedIds,
          },
        });
      }
    } else if (selectedUser) {
      selectedUser.type === "davetli"
        ? deleteInviteds({
            variables: { ids: [selectedUser.id] },
          })
        : deleteUsers({
            variables: { ids: [selectedUser.id] },
          });
    }
  };

  const handleDelDepSubmit = (values) => {
    deleteFunctionUser({
      variables: {
        function_id: depToRemove.function.id,
        user_id: selectedUser.id,
      },
    });
  };

  const handleSubmit = (values) => {
    let type = values.type === "1" ? "Manager" : "Key User";
    updateDefaultRole({
      variables: {
        user_id: values.id,
        default_role: type,
      },
    });
  };

  const handleInsSubmit = (values) => {
    console.log('davet gönderildi')
    nhost.auth.register({
      email: values.email,
      password: "demo1001",
      options: {
        userData: {
          type: values.type,
        },
      },
    }).then(result=>{
      //insertOrgUser({variables: {object: {org_id: org_id,user_id: result.user.id}}})
      document.body.click()
      refetch()
    }).catch(err=>console.log(err))
  };

  const userDeletePopover = (props) => {
    return (
      <Popover id="popover-basic" {...props}>
        <Popover.Title>
          {isCheck.length ? (
            <span>
              <strong>{isCheck.length}</strong>{" "}
              <span className="text-danger">
                kullanıcı veya davetli tamamen silinecektir.
              </span>{" "}
              <p>
                Kullanıcıları sadece departmandan silmek için Birimler
                kısmındaki departmanların üzerine tıklayınız.
              </p>
            </span>
          ) : (
            <span>
              {selectedUser && selectedUser.type === "davetli" ? (
                <span className="text-danger">
                  Davetli listesinden silinecektir.
                </span>
              ) : (
                <span className="text-danger">
                  Kullanıcı tamamen silinecektir.
                </span>
              )}
              {selectedUser && selectedUser.type !== "davetli" && (
                <p>
                  Sadece departmandan silmek için Birimler kısmındaki
                  departmanların üzerine tıklayınız.
                </p>
              )}
            </span>
          )}
        </Popover.Title>
        <Popover.Content>
          <div className="d-flex">
            <LoadingButton
              loading={""}
              onClick={handleDelUsersSubmit}
              variant="danger"
            >
              Sil
            </LoadingButton>
            <button
              onClick={() => document.body.click()}
              className="btn btn-secondary ml-1"
            >
              İptal
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const depDeletePopover = (props) => {
    return (
      <Popover id="popover-basic" {...props}>
        <Popover.Title>
          <p>
            Kullanıcı yetkileri <strong>{depToRemove.function.name}</strong>{" "}
            biriminden kaldıralacaktır.
          </p>
        </Popover.Title>
        <Popover.Content>
          <div className="d-flex">
            <LoadingButton
              loading={""}
              onClick={handleDelDepSubmit}
              variant="danger"
            >
              Kaldır
            </LoadingButton>
            <button
              onClick={() => document.body.click()}
              className="btn btn-secondary ml-1"
            >
              İptal
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const depInsertPopover = (props) => {
    return (
      <Popover className="depInsertPopover" id="popover-basic" {...props}>
        <Popover.Title>
          <div className="form-group row">
            <label htmlFor="type" className="col-sm-3 col-form-label">
              Birim Seç
            </label>
            <div className="col-sm-9">
              <Select
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                name="function"
                placeholder="Yazmaya başlayın..."
                autoFocus
              />
            </div>
          </div>
        </Popover.Title>
        <Popover.Content>
          <div className="d-flex">
            <LoadingButton loading={""} variant="primary">
              Ekle
            </LoadingButton>
            <button
              onClick={() => document.body.click()}
              className="btn btn-warning ml-1"
            >
              İptal
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const typeUpdatePopover = (props) => {
    return (
      <Popover className="depInsertPopover" id="popover-basic" {...props}>
        <Formik
          initialValues={formState}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            dirty,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Popover.Title>
                <div className="form-group row">
                  <label htmlFor="type" className="col-sm-3 col-form-label">
                    Kullanıcı Tipi
                  </label>
                  <div className="col-sm-9">
                    <select
                      id="type"
                      className="form-control"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={1} key={1}>
                        Manager
                      </option>
                      <option value={2} key={2}>
                        Key User
                      </option>
                    </select>
                    {errors.select && touched.select && (
                      <div className="text-danger mt-1 ml-2">
                        {errors.select}
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Title>
              <Popover.Content>
                <div className="d-flex">
                  <LoadingButton
                    loading={""}
                    onClick={handleSubmit}
                    variant="primary"
                  >
                    Değiştir
                  </LoadingButton>
                  <button
                    type="button"
                    onClick={() => document.body.click()}
                    className="btn btn-warning ml-1"
                  >
                    İptal
                  </button>
                </div>
              </Popover.Content>
            </form>
          )}
        </Formik>
      </Popover>
    );
  };

  const insertUser = (props) => {
    return (
      <Popover className="depInsertPopover" id="popover-basic" {...props}>
        <Popover.Title>
        Kullanıcıya davet gönder
        </Popover.Title>
        <Popover.Content>
        <Formik
          initialValues={formState}
          onSubmit={handleInsSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            dirty,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                  <label htmlFor="type" className="col-sm-3 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="email"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <div className="text-danger mt-1 ml-2">
                        {errors.select}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="type" className="col-sm-3 col-form-label">
                    Tip
                  </label>
                  <div className="col-sm-9">
                    <select
                      id="type"
                      className="form-control"
                      name="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                    >
                    <option>Tip Seçin...</option>
                    <option value="superAdmin">superAdmin</option>
                    <option value="superUser">superUser</option>
                    <option value="cLevel">cLevel</option>
                    <option value="director">director</option>
                    <option value="manager">manager</option>
                    <option value="keyUser">keyUser</option>
                    <option value="superAdmin">superAdmin</option>
                    </select>
                    {errors.type && touched.type && (
                      <div className="text-danger mt-1 ml-2">
                        {errors.select}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    Gönder
                  </button>
                  <button
                    type="button"
                    onClick={() => document.body.click()}
                    className="btn btn-warning ml-1"
                  >
                    İptal
                  </button>
                </div>
            </form>
          )}
        </Formik>
        </Popover.Content>
      </Popover>
    );
  };

  const popoverDel = (props) => {
    return (
      <Popover id="popover-basic" {...props}>
        <Popover.Title>
          <span className="text-danger">
            Oragnizasyon ve altındaki tüm departmanlar, fonksiyonlar ve roller
            silinecek. Bu geri dönüşü olmayan bir işlemdir.
          </span>{" "}
        </Popover.Title>
        <Popover.Content>
          <div className="col-md-12">
            <div className="d-flex">
              <button
                onClick={handleSubmit}
                className="btn btn-danger"
                type="button"
              >
                {mutLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Yine de Sil
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  document.body.click();
                }}
                className="btn btn-secondary ml-2"
                type="button"
              >
                İptal
              </button>
            </div>
            {mutError && (
              <div className="text-danger">
                Bir hata oluştu, lütfen tekrar deneyiniz.
              </div>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div>
      <div>
        <h5>Orgs</h5>
        <div className="mt-2">
          <table className="table table-striped table-bordered text-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th>İsim</th>
                <th scope="col">id</th>
                <th scope="col">Eklenme</th>
                <th scope="col">Sektör</th>
                <th scope="col">Ebitda</th>
                <th scope="col">HC</th>
                <th scope="col">Layer</th>
                <th scope="col">Revenue</th>
                <th scope="col" >
              Düzenle
            </th>
              </tr>
            </thead>
            <tbody>
              {godData &&
                godData.organizations &&
                godData.organizations.map((i, index) => (
                  <tr key={i.id}>
                    <td>{index + 1}</td>
                    <td>{i.name}</td>
                    <td>{i.id}</td>
                    <td>
                        <small>
                            <strong>
                            {moment(i.created_at).format(
                        "Do MMMM YYYY, dddd,  HH:MM:SS "
                      )}
                            </strong>
                        </small>
                    </td>
                    <td>{i.industry}</td>
                    <td>{i.ebitda.toLocaleString("tr")}</td>
                    <td>{i.hc}</td>
                    <td>{i.layer}</td>
                    <td>{i.revenue.toLocaleString("tr")}</td>
                    <td className="centered">
                <span
                  className="cursor-pointer text-success mr-2"
                  onClick={()=>{setUpdateShow(true); setOrg_id(i.id)}}
                >
                  <i
                    id={i.id}
                    className="nav-icon i-Pen-2 font-weight-bold"
                  ></i>
                </span>
                <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={popoverDel}
                      rootClose
                    >
                <span
                  className="cursor-pointer text-danger mr-2"
                  onClick={() => setOrg_id(i.id)}
                >
                  <i
                    id={i.id}
                    className="nav-icon i-Close-Window font-weight-bold"
                  ></i>
                </span>
                </OverlayTrigger>
              </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-5">
        <h5>Users</h5>
        <div className="d-flex justify-content-between mt-3">
        <div className="input-group ">
        <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={insertUser}
                                rootClose
                              >
                  <div className="row d-flex align-items-center cursor-pointer">
                    <i className="i-Add ml-4"></i>
                    <span className="iconText ml-2" >
                      {" "}
                        Davet Gönder
                    </span>
                </div>
                </OverlayTrigger>
        </div>
      </div>
        <table
          id="user_table"
          className="table table-striped table-bordered text-center"
        >
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <div className="form-group">
                  <div className="input-right-icon">
                    <input
                      type="text"
                      className="form-control filterInput"
                      name="display_name"
                      id="display_name"
                      placeholder="Yazmaya başlayın ..."
                      onKeyUp={onFilterApply}
                    />
                    <span className="span-right-input-icon">
                      <i className="ul-form__icon i-Filter-2"></i>
                    </span>
                  </div>
                </div>
                Email
              </th>
              <th scope="col">
                <div className="form-group">
                  <div className="input-right-icon">
                    <input
                      type="text"
                      className="form-control filterInput"
                      name="type"
                      id="type"
                      placeholder="Yazmaya başlayın ..."
                      onKeyUp={onFilterApply}
                    />
                    <span className="span-right-input-icon">
                      <i className="ul-form__icon i-Filter-2"></i>
                    </span>
                  </div>
                </div>
                Tipi
              </th>
              <th scope="col">Organizasyonlar</th>
              <th scope="col">Birimler</th>
              <th scope="col">Son Giriş</th>
              <th scope="col">
                <div className="d-flex justify-content-end">
                  <span
                    className="cursor-pointer text-danger mr-2 text-16"
                    style={isCheck.length < 2 ? { visibility: "hidden" } : null}
                  >
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={userDeletePopover}
                      rootClose
                    >
                      <i className="nav-icon i-Close-Window font-weight-bold"></i>
                    </OverlayTrigger>
                  </span>
                  <span>
                    <CheckBox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filterState.data && filterState.data.length
              ? filterState.data.map((user, i) => {
                  return (
                    <tr key={user.id}>
                      <th scope="row">{i + 1}</th>
                      <td>{user.display_name || user.email}</td>
                      <td>
                        <div style={{ position: "relative" }}>
                          <span className="border p-1">
                            {user.type || <small>Ekle</small>}
                            {user.display_name && (
                              <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={typeUpdatePopover}
                                rootClose
                              >
                                <i
                                  id={user.id}
                                  onClick={() => setUser(user)}
                                  style={{
                                    color: "teal",
                                    position: "absolute",
                                    top: "-3px",
                                  }}
                                  className="nav-icon i-Pen-2 text-12 font-weight-bold cursor-pointer"
                                ></i>
                              </OverlayTrigger>
                            )}
                          </span>
                        </div>
                      </td>
                      <td>
                        {user.user_orgs.map((i) => {
                          return (
                            <span
                              className="badge badge-pill bg-gray-400 ml-2"
                              key={i.organization.id}
                            >
                              {i.organization.name}
                            </span>
                          );
                        })}
                      </td>
                      <td>
                        <div className="d-flex">
                          {user.function_users &&
                            user.function_users.map((i) => (
                              <div
                                style={{ position: "relative" }}
                                onClick={() => {
                                  setDepToRemove(i);
                                  setUser(user);
                                }}
                                key={i.function.id}
                                className="badge badge-pill bg-gray-400 ml-2"
                              >
                                <span>
                                  {i.function.name}
                                  <OverlayTrigger
                                    key={i.function.id}
                                    trigger="click"
                                    placement="bottom"
                                    overlay={depDeletePopover}
                                    rootClose
                                  >
                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "-5px",
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      className="i-Close-Window"
                                    ></i>
                                  </OverlayTrigger>
                                </span>
                              </div>
                            ))}
                          {user.type === "manager" ||
                          user.type === "keyUser" ? (
                            <OverlayTrigger
                              trigger="click"
                              placement="bottom"
                              overlay={depInsertPopover}
                              rootClose
                            >
                              <div className="d-flex text-11 justify-content-between ml-4">
                                <div className="input-group">
                                  <div
                                    className="row d-flex align-items-center cursor-pointer"
                                    onClick={() => {
                                      setUser(user);
                                    }}
                                  >
                                    <i className="iconText i-Add ml-2 font-weight-bold"></i>
                                    <span className="iconText ml-1">
                                      {" "}
                                      Birim Ekle
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </OverlayTrigger>
                          ) : user.type === "davetli" ? (
                            <small className="ml-2">
                              Kayıt olduktan sonra güncelleyebilirsiniz
                            </small>
                          ) : (
                            <small className="ml-2">Tümü</small>
                          )}
                        </div>
                      </td>
                      <td>
                        <small>
                          <strong>
                            {user.display_name
                              ? moment(user.last_seen).fromNow()
                              : "Davet gönderildi"}
                          </strong>
                        </small>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <button onClick={()=>props.setUserData(user)} className="btn btn-secondary btn-sm">Login As</button>
                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={userDeletePopover}
                            rootClose
                          >
                            <span
                              className={
                                isCheck.length < 2
                                  ? "cursor-pointer text-danger mr-2 ml-2"
                                  : "cursor-pointer text-danger mr-2 ml-2 disabledButton"
                              }
                              onClick={() => setUser(user)}
                            >
                              <i
                                className={
                                  isCheck.length < 2
                                    ? "nav-icon i-Close-Window font-weight-bold"
                                    : "nav-icon i-Close-Window font-weight-bold disabledButton"
                                }
                              ></i>
                            </span>
                          </OverlayTrigger>
                          <span>
                            <CheckBox
                              key={user.id}
                              type="checkbox"
                              id={user.id}
                              handleClick={handleSelectOne}
                              isChecked={isCheck.includes(user.id)}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <UpdateOrgModal
        user={user}
        show={updateShow}
        org_id={org_id}
        onHide={handleClose}
        refetch={refetch}
        size="lg"
        orgs={godData&&godData.organizations}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    setUserData: PropTypes.func.isRequired,
    orgData: state.org.orgData,
    efficiency: state.org.efficiency,
    user: state.user
  };
};

export default connect(mapStateToProps, { setUserData })(SemiGod);
