import { lazy } from "react";
import MyOrganizations from "./MyOrganizations";
import { authRoles } from "app/auth/authRoles";

const Kpis = lazy(() => import("./MyOrganizations"));


const myOrgRoutes = [
  {
    path: "/myorgs",
    component: MyOrganizations,
    auth: authRoles.sa
  }
];

export default myOrgRoutes;
