import { gql } from '@apollo/client';

const GET_USER_ORGS = gql`
query getUserOrgs($user_id: uuid!) {
  users(where: {id: {_eq: $user_id}}) {
    user_orgs {
      org_id
      organization {
        created_at
        ebitda
        hc
        id
        industry
        layer
        name
        revenue
        functions {
          fte {
            fte
          }
        }
        roles_aggregate {
          aggregate {
            sum {
              existing
            }
          }
        }
      }
    }
  }
}
`
export default GET_USER_ORGS;