import { gql } from '@apollo/client';

const DELETE_FUNCTION_USER= gql`
mutation deleteFunctionUser($function_id: Int!, $user_id: uuid!) {
    delete_function_users_by_pk(function_id: $function_id, user_id: $user_id) {
        function_id
        user_id
      }
}
`; 

export default DELETE_FUNCTION_USER