import { gql } from "@apollo/client";

const UPDATE_ORG = gql`
  mutation updateOrg($org_id: Int!, $_set: organizations_set_input!) {
    update_organizations_by_pk(pk_columns: { id: $org_id }, _set: $_set) {
      id
    }
  }
`;
export default UPDATE_ORG;
