import React from "react";
import { Spinner, Button } from "react-bootstrap";

const LoadingButton = ({
  variant = "primary",
  loading = false,
  animation,
  spinnerSize,
  buttonSize,
  children,
  className,
  type,
  onClick,
  disabled
}) => {
  return (
    <Button
      variant={variant}
      className={`d-flex flex-row align-items-center ${className}`}
      size={buttonSize}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <Spinner className="mr-2" animation={animation} size={spinnerSize} />
      )}
      {children}
    </Button>
  );
};

export default LoadingButton;
