import { gql } from '@apollo/client';

  const GET_ORGANIZATION_DATA = gql`
  query getOrganizationData($org_id: Int!) {
    organizations(where: {id: {_eq: $org_id}}) {
      created_at
      ebitda
      id
      industry
      name
      revenue
      updated_at
      layer
      hc
      calculated_workday {
          organization
          work_hours
          workdays
          year
      }
      flattened_functions(order_by: {id: asc}) {
        array_to_string
        category
        id
        level
        level3
        level4
        name
        org_id
        parent
        regression
        subparent
        trend
        __typename @skip(if: true)
        roles(order_by: {role: {role_level: {grade: asc}}}) {
          role {
            existing
            id
            title
            __typename @skip(if: true)
            fte {
              fte
            }
            role_tags {
              tag {
                tag
              }
            }
            role_level {
              level
            }
          }
        }
        function_fte {
          fte
        }
      }
    }
  }
  `
  export default GET_ORGANIZATION_DATA;
    