import { gql } from '@apollo/client';

const SEMIGOD = gql`
query semiGodQueries {
    organizations {
      name
      id
      created_at
      industry
      updated_at
      ebitda
      hc
      layer
      revenue
    }
    users {
      id
      display_name
      type
      last_seen
      function_users {
        function {
          id
          name
        }
      }
      user_orgs {
        organization {
          id
          name
        }
      }
    }
  }
`

export default SEMIGOD;
