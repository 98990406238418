import { gql } from '@apollo/client';

const UPDATE_DEFAULTROLE = gql`
  mutation MyMutation($user_id: uuid!, $default_role: String!) {
    update_auth_accounts(where: {user_id: {_eq: $user_id}}, _set: {default_role: $default_role}) {
      affected_rows
    }
  }
  `
  
  export default UPDATE_DEFAULTROLE;
    