export const GET_WORKDAY_DATA = "GET_WORKDAY_DATA"
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_FUNCTION_LIST = "GET_FUNCTION_LIST"
export const GET_ORG_DATA = "GET_ORG_DATA"
export const GET_EFFICIENCY = "GET_EFFICIENCY"
export const GET_USER_ORGS = "GET_USER_ORGS"

export const getWorkDayData = (result) => dispatch => {
    dispatch({
      type: GET_WORKDAY_DATA,
      payload: result
    });
};

export const getDepartments = (result) => dispatch => {
    dispatch({
      type: GET_DEPARTMENTS,
      payload: result
    });
};

export const getEfficiency = (result) => dispatch => {
  dispatch({
    type: GET_EFFICIENCY,
    payload: result
  });
};

export const getOrgData = (result) => dispatch => {
  dispatch({
    type: GET_ORG_DATA,
    payload: result
  });
};

export const getUserOrgs = (result) => dispatch => {
  dispatch({
    type: GET_USER_ORGS,
    payload: result
  });
};