import {
  GET_DEPARTMENTS,
  GET_WORKDAY_DATA,
  GET_EFFICIENCY,
  GET_ORG_DATA,
  GET_USER_ORGS
  } from "../actions/OrgActions";
  
  const initialState = {
    orgData: [],
    workDayData: [],
    departmentData: [],
    efficiency: [80],
    org_id: null
  };
  
  const OrgReducer = function(state = initialState, action) {
    switch (action.type) {
      case GET_ORG_DATA: {
        return {
          ...state,
          orgData: [...action.payload]
        };
      }
      case GET_WORKDAY_DATA: {
        return {
          ...state,
          workDayData: [...action.payload]
        };
      }
      case GET_DEPARTMENTS: {
        return {
          ...state,
          departmentData: [...action.payload]
        };
      }
      case GET_EFFICIENCY: {
        return {
          ...state,
          efficiency: [...action.payload]
        };
      }
      case GET_USER_ORGS: {
        return {
          ...state,
          userOrgs: action.payload
        };
      }
      default: {
        return {
          ...state
        };
      }
    }
  };
  
  export default OrgReducer;
  