import React, { Fragment, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import GET_USER_ORGS from "app/services/graphql/GetUserOrgs";
import { connect } from "react-redux";
import "./breadCss.css";
import PropTypes from "prop-types";
import { getUserOrgs } from "../../app/redux/actions/OrgActions";
import Select from "react-select";
import { useLocation } from "react-router";

const Breadcrumb = (props) => {
  const { routeSegments, user, org_id } = props;
  const [selectedOrg, setSelectedOrg] = useState(null);
  const location = useLocation();

  const [getOrgs, { loading, error, data }] = useLazyQuery(GET_USER_ORGS, {
    variables: { user_id: user.id },
  });
  const orgs = data && data.users[0].user_orgs;

  if (error) console.log(error);

  useEffect(() => {
    let componentMounted = true;
    selectedOrg&&componentMounted&& props.getUserOrgs(selectedOrg.value);
    return () => {
      componentMounted = false;
  }
  }, [selectedOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let componentMounted = true;
    componentMounted&&user&& getOrgs();
    return () => {
      componentMounted = false;
  }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultValue = orgs && orgs.filter((i) => i.org_id === org_id)[0];

  const handleSubmit = (values) => {
    setSelectedOrg(values);
  };

  const options =
    orgs &&
    orgs.map((i) => ({
      label: i.organization.name,
      value: i.org_id,
    }));

  return (
    <Fragment>
      <div className="breadcrumb">
        {routeSegments ? (
          <Fragment>
            <h1>{routeSegments[routeSegments.length - 1]["name"]}</h1>
          </Fragment>
        ) : null}
        {defaultValue &&
        orgs &&
        orgs.length > 1 &&
        location &&
        location.pathname !== "/myorgs" ? (
          <div
            style={{ borderLeft: "1px solid black", width: "150px" }}
            className="ml-3"
          >
            {loading ? (
              <div className="spinner-border text-primary ml-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (base, state) => ({
                    ...base,
                    border: "none", // default border color
                    //borderBottom: "1px solid black",
                    borderRadius: 0,
                    boxShadow: "none", // no box-shadow
                    color: "blue",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#085ff7",
                  }),
                  dropdownIndicator: (base) => ({ ...base, color: "#085ff7" }),
                  indicatorSeparator: (base) => ({ ...base, display: "none" }),
                  input: (base) => ({
                    ...base,
                    color: "#085ff7",
                  }),
                }}
                options={options}
                value={{
                  label: defaultValue.organization.name,
                  value: defaultValue.org_id,
                }}
                name="org"
                onChange={handleSubmit}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="separator-breadcrumb border-top"></div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    getUserOrgs: PropTypes.func.isRequired,
    user: state.user,
    org_id: state.org.userOrgs,
  };
};

export default connect(mapStateToProps, { getUserOrgs })(Breadcrumb);