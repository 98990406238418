import React, { Suspense, useEffect } from "react";
import "../styles/app/app.scss";

import { Router } from "react-router-dom";
import history from "@history";

import { renderRoutes } from "react-router-config";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getOrgData, getUserOrgs } from "./redux/actions/OrgActions";

import { useLazyQuery} from "@apollo/client";
import GET_ORGANIZATION_DATA from "./services/graphql/GetOrganizationData";
import GET_USER_ORG_IDS from "./services/graphql/GetUserOrgIds";

function App(props) {
  const { user, org_id } = props

  const [getuserOrgIds, { error, data }] = useLazyQuery(GET_USER_ORG_IDS, {
    variables: { user_id: user.id },
  });

  useEffect(()=>{
    let componentMounted = true;
    if(user&&user.id&&componentMounted)getuserOrgIds()
    return () => {
      componentMounted = false;
  }
  },[user]) // eslint-disable-line react-hooks/exhaustive-deps

  if(error)console.log(error)

  const [getOrgsData, { data: orgData, error: orgError }] = useLazyQuery(GET_ORGANIZATION_DATA);

  useEffect(()=>{
    let componentMounted = true;
    if(org_id&&componentMounted)getOrgsData({variables: { org_id: org_id}})
    return () => {
      componentMounted = false;
  }
  },[org_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    let componentMounted = true;
    if(orgData&&componentMounted)props.getOrgData(orgData.organizations)
    return () => {
      componentMounted = false;
  }
  },[orgData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    let componentMounted = true;
  //inject global org_id of the first organization of the user
  if(data&&data.user_orgs&&data.user_orgs[0]&&!org_id&&componentMounted)props.getUserOrgs(data.user_orgs[0].org_id)
  return () => {
    componentMounted = false;
}
  },[data])// eslint-disable-line react-hooks/exhaustive-deps

  
  if(orgError)console.log(orgError)

  return (
      <Suspense fallback={<Loading></Loading>}>
        <Router history={history}>{renderRoutes(RootRoutes)}</Router>
      </Suspense>
  );
}

const mapStateToProps = state => ({
  getOrgData: PropTypes.func.isRequired,
  getUserOrgs: PropTypes.func.isRequired,
  user: state.user,
  org_id: state.org.userOrgs
});

export default connect(mapStateToProps, { getOrgData, getUserOrgs })(App);