import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { Provider } from "react-redux";
import AppContext from "./app/appContext";
import { Store } from "./app/redux/Store";
import routes from "./app/RootRoutes";
import Auth from "./app/auth/Auth";
import { NhostProvider } from "@nhost/react";
import { nhost } from './nhost'


ReactDOM.render(
  <NhostProvider nhost={nhost}>
    <NhostApolloProvider nhost={nhost}>
      <AppContext.Provider value={{ routes }}>
        <Provider store={Store}>
          <Auth>
            <App />
          </Auth>
        </Provider>
      </AppContext.Provider>
    </NhostApolloProvider>
  </NhostProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
